//const today = new Date();
//const current_datetime = new Date(today.toString().replace(/-/g, '/'));

/* export const filesCategories = [
    {
        id: "1",
        name: "161 עובד"
    },
   
   
]; */

export const inputsBuilder = [

    {
        'name' : 'signer_tz',
        'label' : 'תעודת זהות',
        'type' : 'number',
        'defaultValue': '',
        
        
    },
    {
        'name' : 'signer_fullName',
        'label' : 'שם מלא',
        'defaultValue': ''
    },

    {
        'name' : 'reason',
        'label' : 'סיבה למפרעה',
        'options' : [
                    'איחור בהגשת יומן',
                    'חופשה',
                    'הפרשי תעריף',
                    'הפרשי נסיעות',
                    'החזר ביטוח לאומי',
                    'החזר מס',
                    'תשלום מחלה',
                    'מפרעה קבועה',
                    'תיקון מספר שעות עבודה',
                    'אחר',
                    //'',
                    //'',
                ],
        'defaultValue': 'סיבה למפרעה',
        //'noCkeck' : true
    },

    {
        'name' : 'notes',
        'label' : 'הערות',
        'defaultValue': '',
        'noCkeck' : true
    },
    

]


/* 

signer_address: '',
        signer_passport: '',
        date_birth: formApiData?.signer.date_birth ? convertDBDate(formApiData.signer.date_birth) : current_datetime,
        date_aliya: current_datetime,
        sex: 'מין', */