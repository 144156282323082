import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import FormControl from '@material-ui/core/FormControl';
//import {checkTz } from '../../../Components/utils/helpFunctions'

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


export default function Taxes(props) {

  const {showErrors, idChild, addTaxes } = props

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectValues = [    'בחר...',
    'עבודה',
    'קצבה',
    'מלגה',
    'אחר'
  ]

  const [formData, setFormData] = useState(defaultValueForm(idChild,selectValues ));

  const onChange = (e) => {

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })

  }

  useEffect(() => {

    if(
      
      formData.name && formData.address &&
      formData.select !== 'בחר...' &&
      formData.taxNum && formData.in && formData.mas
      ) {

      addTaxes(formData);

    }

  }, [formData, addTaxes, selectValues])
  


  return (
    <div className='children'>

        <FormControl >

          <TextField 
              //multiline
              /* maxRows={4} */
              //type='number'
              label='שם המעביד/משלם המשכורת'
              value={formData.name}
              name='name'
              onChange={onChange}
              error={ showErrors && !formData.name }
              //helperText="שגיאה"
              />

        </FormControl>

        <FormControl >

          <TextField 
              //multiline
              /* maxRows={4} */
              //type='number'
              label='כתובת'
              value={formData.address}
              name='address'
              onChange={onChange}
              error={ showErrors && !formData.address }
              //helperText="שגיאה"
              />

        </FormControl>


        <FormControl >

          <TextField 
              //multiline
              /* maxRows={4} */
              type='number'
              label='מספר תיק ניכויים'
              value={formData.taxNum}
              name='taxNum'
              onChange={onChange}
              error={ showErrors && !formData.taxNum }
              //helperText="שגיאה"
              />

        </FormControl>

        <FormControl  className='selectInput'>
            <Select
                
                value={formData.select}
                name='select'
                onChange={onChange}
                label='בחר סוג ההכנסה'
                error={showErrors && (formData.select === selectValues[0] )} >
                
                <MenuItem value={selectValues[0]}>{selectValues[0]}</MenuItem>
                <MenuItem value={selectValues[1]}>{selectValues[1]}</MenuItem>
                <MenuItem value={selectValues[2]}>{selectValues[2]}</MenuItem>
                <MenuItem value={selectValues[3]}>{selectValues[3]}</MenuItem>

                
            </Select>
        </FormControl>

        <FormControl >

          <TextField 
              //multiline
              /* maxRows={4} */
              type='number'
              label='הכנסה חודשית'
              value={formData.in}
              name='in'
              onChange={onChange}
              error={ showErrors && !formData.in }
              //helperText="שגיאה"
              />

        </FormControl>

        <FormControl >

          <TextField 
              //multiline
              /* maxRows={4} */
              type='number'
              label='המס שנוכה'
              value={formData.mas}
              name='mas'
              onChange={onChange}
              error={ showErrors && !formData.mas }
              //helperText="שגיאה"
              />

        </FormControl>

    </div>
  )
}

function defaultValueForm(idChild,selectValues) {


  let objReturn = {

    'idChild' : 'tax' + idChild,
    'name' : '',
    'address' : '',
    'taxNum' : '',
    'select' : selectValues[0],
    'in' : '',
    'mas' : ''

  };

  

  return objReturn;

 
}