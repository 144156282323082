import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import FormControl from '@material-ui/core/FormControl';
import {checkTz, convertDateToDisplay} from '../../../Components/utils/helpFunctions'

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import he from 'date-fns/locale/he';
registerLocale('he', he)



export default function Childrens(props) {

  const {showErrors, idChild, addChildrens } = props

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectValues = [    'בחר...',
    'הילד נמצא בחזקתך',
    'את/ה מקבל/ת בגינו קצבת ילדים מב"ל'
  ]

  const [formData, setFormData] = useState(defaultValueForm(idChild,selectValues ));

  const onChange = (e) => {

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })

  }

  

  useEffect(() => {

    formData.birthday2 = convertDateToDisplay(formData.birthday);

    if(formData.name && checkTz(formData.tz) && formData.select !== 'בחר...' ) {

        addChildrens(formData);

    }

  }, [formData, addChildrens, selectValues])
  



    //For datePicker
    const handleChangeDate = (date,nameInput) => {

        setFormData({
            ...formData,
            [nameInput]: date,
          })
        
    }

  return (
    <div className='children'>

        <FormControl  className='selectInput'>
            <Select
                
                value={formData.select}
                name='select'
                onChange={onChange}
                label={selectValues[0]}
                error={showErrors && (formData.select === selectValues[0] )} >
                
                <MenuItem value={selectValues[0]}>{selectValues[0]}</MenuItem>
                <MenuItem value='1'>{selectValues[1]}</MenuItem>
                <MenuItem value='2'>{selectValues[2]}</MenuItem>

                
            </Select>
        </FormControl>

        <FormControl >

          <TextField 
              //multiline
              /* maxRows={4} */
              //type='number'
              label='שם הילד/ה'
              value={formData.name}
              name='name'
              onChange={onChange}
              error={ showErrors && !formData.name }
              //helperText="שגיאה"
              />

        </FormControl>

        <FormControl >

            <TextField 
                label='מספר זהות'
                value={formData.tz}
                name="tz"
                onChange={onChange}
                type='number'
                error={ showErrors && (!checkTz(formData.tz)) }
                //helperText="שגיאה"
                />

        </FormControl>

        <FormControl className='dateWrapper'>
            <div className='titleDate'>תאריך לידה</div>

            <DatePicker 
                showYearDropdown = {true}
                selected={formData.birthday}
                dateFormat='dd/MM/yy'
                locale="he"
                onChange={(date)=>handleChangeDate(date, 'birthday')}
                //disabled={this.props.is_disabled ? true : false}
            />

        </FormControl>

    </div>
  )
}

function defaultValueForm(idChild,selectValues) {

  const today = new Date();
  const current_datetime = new Date(today.toString().replace(/-/g, '/'));

  let objReturn = {

    'idChild' : 'child' + idChild,
    'name' : '',
    'select' : selectValues[0],
    'tz' : '',
    'birthday' : current_datetime

  };

  

  return objReturn;

 
}