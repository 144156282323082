import React, { useState } from 'react'
import TextField from '@mui/material/TextField';

import { map } from "lodash" //https://lodash.com/docs/4.17.15#map

import {checkTz, convertDateToDisplay} from '../../../Components/utils/helpFunctions'

import SignatureCanvas from 'react-signature-canvas';
import FormControl from '@material-ui/core/FormControl';
import {Button, Spinner } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0

//import validator from "validator"; //npm install validator
import { toast } from 'react-toastify' //npm i react-toastify
import getDataFromApi from './../../../Components/api/getDataFromApi'
import { inputsBuilder } from './inputsBuilder';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
/* https://www.npmjs.com/package/react-datepicker */
/* ALL PROPS */
/* https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md */

import { registerLocale } from  "react-datepicker";
import he from 'date-fns/locale/he';
registerLocale('he', he)


export default function FormFields(props) {

    const {responseData, search} = props;
    
    const [formData, setFormData] = useState(defaultValueForm(responseData,inputsBuilder));
    const [showErrors, setShowErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(false);

    //console.log(formData);

    const onChange = (e) => {

        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        })

    }
   
    //For datePicker
    const handleChangeDate = (date,nameInput) => {

        setFormData({
            ...formData,
            [nameInput]: date,
          })
        
    }

   
    const sendForm = () => { 

        
        setShowErrors(true);

        if(!checkErrors(formData,trimmedDataURL,inputsBuilder)) {

            toast.error('נא למלא את השדות  חובה');

        } else {

            const sendObj = {
                id : search.id,
                token: search.token,
            }

            sendObj.formData = formData;
            sendObj.sign = trimmedDataURL;

            sendObj.formData.checkDate2 = formData.checkDate ? convertDateToDisplay(sendObj.formData.checkDate) : '';
            

            //console.log(sendObj);

            sendtoApi('saveFormData', 'DocData', sendObj, setLoading, setPdfUrl);

        }
      

    }

    const endSign = () => {

        setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL('image/png'));
        //console.log('end');

    }

    const onBegin = () => {

        //console.log('onBegin');
    }

    let sigPad = trimmedDataURL;

    const clear = () => {
        setTrimmedDataURL(null);
        sigPad.clear(sigPad);
    }

    //console.log('inputsBuilder', inputsBuilder);

    

  return (
    <div className='formFields' >

            {/* <header>
                <p>שנת המס: <strong>{formApiData.general.masYear}</strong></p>
            </header> */}
                <form noValidate autoComplete="off">

                    {/* https://v4.mui.com/components/text-fields/ */}

{/*                     <div className='titles'>
                        <h2>פרטי העובד</h2>
                    </div> */}

                    <FormControl >

                        <TextField 
                            label={inputsBuilder[0].label}
                            value={formData[inputsBuilder[0].name]}
                            name={inputsBuilder[0].name}
                            onChange={onChange}
                            type={inputsBuilder[0].type}
                            error={ showErrors && (!checkTz(formData[inputsBuilder[0].name]))}
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <FormControl >

                        <TextField 
                            label={inputsBuilder[1].label}
                            value={formData[inputsBuilder[1].name]}
                            name={inputsBuilder[1].name}
                            onChange={onChange}
                            error={ showErrors && !formData[inputsBuilder[1].name]}
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <FormControl >

                        <TextField 
                            label={inputsBuilder[2].label}
                            value={formData[inputsBuilder[2].name]}
                            name={inputsBuilder[2].name}
                            onChange={onChange}
                            /* error={ showErrors && !formData[inputsBuilder[2].name]} */
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <FormControl className='dateWrapper'>
                        <div className='titleDate'>{inputsBuilder[3].label}</div>
                        <DatePicker 
                            showYearDropdown = {true}
                            selected={formData[inputsBuilder[3].name]}
                            dateFormat='dd/MM/yy'
                            locale="he"
                            onChange={(date)=>handleChangeDate(date, inputsBuilder[3].name)}
                            //disabled={this.props.is_disabled ? true : false}
                        />

                    </FormControl>

                    <FormControl >

                        <TextField 
                            multiline
                            /* maxRows={4} */
                            label={inputsBuilder[4].label}
                            value={formData[inputsBuilder[4].name]}
                            name={inputsBuilder[4].name}
                            onChange={onChange}
                            /* error={ showErrors && !formData[inputsBuilder[4].name] } */
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <div className='signCont'>

                        <h3>חתימה</h3>

                        <div className={`canvasCont ${showErrors && !trimmedDataURL && 'error'}`}>

                            <SignatureCanvas
                                ref={(ref) => { sigPad = ref }}
                                // Pre fill sign:
                                //ref={(ref) => { ref.fromDataURL(trimmedDataURL) }}
                                onEnd={endSign}
                                onBegin={onBegin}
                                backgroundColor='white'
                                penColor='black'
                                canvasProps={{ width: 250, height: 200, className: 'sigCanvas' }}
                            />

                        </div>

                        <div className='btnCont'>
                            <button type='button' onClick={clear}>מחיקת חתימה</button>
                        </div>

                    </div>

                    <div className="d-grid gap-2 btnCont">
                        <Button className={`${checkErrors(formData,trimmedDataURL,inputsBuilder) && 'animate__animated animate__bounceIn' }`} variant={!checkErrors(formData,trimmedDataURL,inputsBuilder) ? 'danger' : 'primary'} size="lg" onClick={ sendForm }>
                            {loading ? <Spinner size="" animation="border" variant="light" /> : <span>שליחה</span> }
                        </Button>
                    </div>

                </form>
        {pdfUrl && <a className='pdfUrl' href={pdfUrl} target='_blank' rel="noopener noreferrer">{pdfUrl}</a>}
    </div>
  );
}


function sendtoApi(url, controller, objectToSend, setLoading, setPdfUrl) {

    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')
  
    getData.then((getData) => {

      if (getData.ok) {

        if(getData.url) {

            setPdfUrl(getData.url);

        }

        //setRequest(getData.ok)
        toast.success(getData.ok);

      } else if (getData.error) {

        toast.error(getData.error)

      }
    })
  
    getData.catch((error) => {
      console.log(error)
    })
  
    getData.finally(() => {
      setLoading(false)
    })
  }

function checkErrors(formData,trimmedDataURL,inputsBuilder) {

    let areErrors = false;

    //Check NoEmpty AND SELECT
    map(inputsBuilder, item => {
        
        if(!item.noCkeck) {

            if(item?.options) {

                if(formData[item.name] === item.defaultValue) {
                
                    areErrors = true;
                    
                }


            } else if(!formData[item.name]) {

                areErrors = true;
                //console.log(item.name);

            }

        }
        
    })

    //console.log(areErrors);

    if(
        areErrors ||
        !trimmedDataURL ||
        (!checkTz(formData.signer_tz) ) 
        //(!checkTz(formData.partner_tz) && !formData.partner_passport) ||


        ) {
            return false;

        } else {

            return true;

        }

        

}


function defaultValueForm(responseData,inputsBuilder) {

    const formApiData = (responseData &&  responseData?.data) ? responseData?.data?.docData : '';

    //const today = new Date();
    //const current_datetime = new Date(today.toString().replace(/-/g, '/'));

    let objReturn = {};

    map(inputsBuilder, item => {
              
        objReturn[item.name] = item.defaultValue
        
      })

    //Replace defaults values from API
    //objReturn.sex = 'זכר';
    objReturn.signer_tz = formApiData?.signer.tz ? formApiData.signer.tz : '';
    objReturn.signer_fullName = formApiData?.signer.firstName + ' ' + formApiData?.signer.lastName;
    
    //objReturn.date_birth = formApiData?.signer.date_birth ? convertDBDate(formApiData.signer.date_birth) : current_datetime;

    return objReturn;

   
}


