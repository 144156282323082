import React,{useState} from 'react'

import { Row, Col, ProgressBar } from 'react-bootstrap';

import { RestUrls } from "../api/config";

import axios from 'axios';
import { useDropzone } from 'react-dropzone' /* https://react-dropzone.js.org/ */
import { toast } from 'react-toastify'

import NoPic from './../../img/pic.png'

//import Resizer from "react-image-file-resizer";
//npm i react-image-file-resizer //https://www.npmjs.com/package/react-image-file-resizer


export default function FileUpload(props) {


    const {responseData, item} = props;

    //console.log(item);
    //console.log(responseData);

    const [picUrl, setPicUrl] = useState(false);
    const [isPicture, setIsPicture] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(null);
    const [uploaded, setUploaded] = useState(false);

    //for test
    

    const onDrop = (acceptedFiles) => {
        
        let file = acceptedFiles[0];
        let filename = file?.name;

        //setFilenameState(filename);

        //console.log(file);

        //const text = file?.type + ' | ' + file?.size + ' | ';
        //setFilenameState2 (text);

        //FOR IPHONE FORMAT
        if( filename.includes('.heic') ) {

            var heic2any = require("heic2any");
            heic2any({
                blob: file,
                toType: 'image/jpeg',
                quality: 0.7
            }).then(blob => {

                let newFile = new File([blob], "sample1.jpg", {type: "image/jpeg"});
        
                setPicUrl(URL.createObjectURL(newFile));
                setIsPicture(true);
                uploadImage(newFile);
               
            }, error => {
                console.log(error);
                //handle errors
            });

        } else {

            setPicUrl(URL.createObjectURL(file));

            if( !filename.includes('.pdf') && (filename.includes('.jpg') || filename.includes('.jpeg') || filename.includes('.png') ) ) {
                setIsPicture(true);
            } else {
                //console.log(filename);
                setIsPicture(false);
            }
            
            uploadImage(file);

        }
        
    }
    
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        //accept: 'pdf,image/jpeg, image/png',
        noKeyboard: true,
        //maxFiles:1,
        //maxSize:4096,
        multiple: false,
        onDrop,
    })

    const uploadImage = (file) => {
       //console.log(file)
    
       fileUploadHandler(file);
       
    }



    const  fileUploadHandler = (file) => {

        // let userJ = JSON.parse(localStorage.getItem('user-info'));
        // let user = userJ.data;

        const fd = new FormData();

        fd.append('formId', responseData.data.id);
        fd.append('catName', item.name);
        fd.append('userfile', file, file.name);

        //console.log(fd);

        axios.post( RestUrls.filesMethod, fd, {

            onUploadProgress: progressEvent => {

                setUploadProgress(Math.round(progressEvent.loaded / progressEvent.total * 100 ));

            }
        })
            .then( res => {

                //console.log(res);
                //console.log(res.data);

                if(res.data?.ok) {

                    toast.success(res.data.ok);
                    setUploaded(true);

                } else {

                    if(res.data?.error) {

                        setPicUrl(false);
                        setIsPicture(false);
                        toast.error(res.data.error);
                        
                        //const errors = JSON.stringify(res.data.errors);
                        //toast.error(errors);
                        

                    } else {

                        toast.error('שגיאה');

                    }
                    
                }

                

                /*  this.setState({
                    sentForm : true, //SENT,
                    uploadError : res.data.uploadError ? res.data.uploadError : '',
                    inputFile: '',
                    name : '',   //File Upload
                    uploadProgress : null, //File Upload
                    selectedFile: null,  //File Uploadת
                    allFilesWorker: []
                }); */
                    
               
            })
            
            .catch((error) => {
                console.log(error)
              })
            
            .finally(() => {
                setUploadProgress(null);
            })
            
    }

    
    

  return (<>

    <Row className='fileCont'>
        
        <Col  md="7" xs="7" className='catName'>
            <h3>{item.name}</h3>
        </Col>
        
        <Col  md="5" xs="5" className='imgCol' >
            <div className="user-file" {...getRootProps()}>
            

            <input {...getInputProps()} />
            {isDragActive ? (
                <img className='drag' src={NoPic} alt={item.name} />
            ) : (
                <img className={`noDrag ${!picUrl && 'noPic'}`} src={ picUrl && isPicture ? picUrl : NoPic } alt={item.name}/>
            )}
            {uploaded && <p className='animate__animated animate__bounce'>לחצו לקובץ נוסף</p>}
            </div>
        </Col>
    </Row>

    {uploadProgress && <ProgressBar now={uploadProgress} /> }
       
    
    </>)
}
