//const today = new Date();
//const current_datetime = new Date(today.toString().replace(/-/g, '/'));


export const inputsBuilder = [

    {
        'name' : 'signer_tz',
        'label' : 'תעודת זהות',
        'type' : 'number',
        'defaultValue': '',
        
        
    },
    {
        'name' : 'signer_fullName',
        'label' : 'שם מלא',
        'defaultValue': ''
    },
    {
        'name' : 'checkNumber',
        'label' : 'מספר המחאה',
        'defaultValue': '',
        'noCkeck' : true
    },
    {
        'name' : 'checkDate',
        'label' : 'תאריך',
        'defaultValue': '',
        'noCkeck' : true
        //'defaultValue': current_datetime,
    },
    {
        'name' : 'rejectReason',
        'label' : 'סיבת השחזור',
        'defaultValue': '',
        'noCkeck' : true
    },
]


/* 

signer_address: '',
        signer_passport: '',
        date_birth: formApiData?.signer.date_birth ? convertDBDate(formApiData.signer.date_birth) : current_datetime,
        date_aliya: current_datetime,
        sex: 'מין', */