import React, { useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';

import TextField from '@mui/material/TextField';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import { map } from "lodash" //https://lodash.com/docs/4.17.15#map

import {checkTz} from '../../../Components/utils/helpFunctions'
//npm install stylis stylis-plugin-rtl
// o npm i stylis-plugin-rtl@2.0.2;
//npm install jss-rtl

import SignatureCanvas from 'react-signature-canvas';
import FormControl from '@material-ui/core/FormControl';

import {Button, Spinner } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0

//import validator from "validator"; //npm install validator
import { toast } from 'react-toastify' //npm i react-toastify

import getDataFromApi from './../../../Components/api/getDataFromApi'

import { inputsBuilder, filesCategories } from './inputsBuilder';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import AddFiles from './AddFiles'

export default function FormFields(props) {

    const {responseData, search} = props;

    //console.log(responseData);
    
    const [formData, setFormData] = useState(defaultValueForm(responseData,inputsBuilder));
    const [showErrors, setShowErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(false);

    //console.log(formData);

    const onChange = (e) => {

        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        })

    }
   
   
    const sendForm = () => { 

        
        setShowErrors(true);

        if(!checkErrors(formData,trimmedDataURL,inputsBuilder,responseData)) {

            toast.error('נא למלא את השדות  חובה');

        } else {

            const sendObj = {
                id : search.id,
                token: search.token,
            }

            sendObj.formData = formData;
            sendObj.sign = trimmedDataURL;

            //sendObj.formData.checkDate2 = formData.checkDate ? convertDateToDisplay(sendObj.formData.checkDate) : '';
            

            //console.log(sendObj);

            sendtoApi('saveFormData', 'DocData', sendObj, setLoading, setPdfUrl);

        }
      

    }

    const endSign = () => {

        setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL('image/png'));
        //console.log('end');

    }

    const onBegin = () => {

        //console.log('onBegin');
    }

    let sigPad = trimmedDataURL;

    const clear = () => {
        setTrimmedDataURL(null);
        sigPad.clear(sigPad);
    }

    //console.log('inputsBuilder', inputsBuilder);

    

  return (
    <div className='formFields' >

        {/* <header>
            <p>שנת המס: <strong>{formApiData.general.masYear}</strong></p>
        </header> */}
        
        <form noValidate autoComplete="off">

            {/* https://v4.mui.com/components/text-fields/ */}

{/*                     <div className='titles'>
                <h2>פרטי העובד</h2>
            </div> */}

            <FormControl >

                <TextField 
                    label={inputsBuilder[0].label}
                    value={formData[inputsBuilder[0].name]}
                    name={inputsBuilder[0].name}
                    onChange={onChange}
                    type={inputsBuilder[0].type}
                    error={ showErrors && (!checkTz(formData[inputsBuilder[0].name]))}
                    //helperText="שגיאה"
                    />

            </FormControl>

            <FormControl >

                <TextField 
                    label={inputsBuilder[1].label}
                    value={formData[inputsBuilder[1].name]}
                    name={inputsBuilder[1].name}
                    onChange={onChange}
                    error={ showErrors && !formData[inputsBuilder[1].name]}
                    //helperText="שגיאה"
                    />

            </FormControl>


            <FormControl  className='selectInput'>
                <Select
                    
                    value={formData[inputsBuilder[2].name]}
                    name={inputsBuilder[2].name}
                    onChange={onChange}
                    label={inputsBuilder[2].label}
                    error={showErrors && (formData[inputsBuilder[2].name] === inputsBuilder[2].defaultValue )} >
                    
                    <MenuItem value={inputsBuilder[2].label}>בחר/י {inputsBuilder[2].label}...</MenuItem>
                    {
                        map(inputsBuilder[2].options, item => {

                            if(true) {
                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                            } else return false })
                    }
                </Select>
            </FormControl>


            <FormControl >

                <TextField 
                    multiline
                    /* maxRows={4} */
                    label={inputsBuilder[3].label}
                    value={formData[inputsBuilder[3].name]}
                    name={inputsBuilder[3].name}
                    onChange={onChange}
                    /* disabled={true} */
                    /* error={ showErrors && !formData[inputsBuilder[4].name] } */
                    //helperText="שגיאה"
                    />

            </FormControl>

            <div className='signCont'>

                <h3>חתימת מגיש הבקשה: </h3>

                <div className={`canvasCont ${showErrors && !trimmedDataURL && 'error'}`}>

                    <SignatureCanvas
                        ref={(ref) => { sigPad = ref }}
                        // Pre fill sign:
                        //ref={(ref) => { ref.fromDataURL(trimmedDataURL) }}
                        onEnd={endSign}
                        onBegin={onBegin}
                        backgroundColor='white'
                        penColor='black'
                        canvasProps={{ width: 250, height: 200, className: 'sigCanvas' }}
                    />

                </div>

                <div className='btnCont'>
                    <button type='button' onClick={clear}>מחיקת חתימה</button>
                </div>

            </div>

            <AddFiles responseData = {responseData} filesCategories = {filesCategories} />

            <div className="d-grid gap-2 btnCont">
                <Button className={`${checkErrors(formData,trimmedDataURL,inputsBuilder,responseData) && 'animate__animated animate__bounceIn' }`} variant={!checkErrors(formData,trimmedDataURL,inputsBuilder,responseData) ? 'danger' : 'primary'} size="lg" onClick={ sendForm }>
                    {loading ? <Spinner size="" animation="border" variant="light" /> : <span>שליחה</span> }
                </Button>
            </div>

        </form>

        {pdfUrl && <a className='pdfUrl' href={pdfUrl} target='_blank' rel="noopener noreferrer">{pdfUrl}</a>}
    </div>
  );
}


function sendtoApi(url, controller, objectToSend, setLoading, setPdfUrl) {

    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')
  
    getData.then((getData) => {

      if (getData.ok) {

        if(getData.url) {

            setPdfUrl(getData.url);

        }

        //setRequest(getData.ok)
        toast.success(getData.ok);

      } else if (getData.error) {

        toast.error(getData.error)

      }
    })
  
    getData.catch((error) => {
      console.log(error)
    })
  
    getData.finally(() => {
      setLoading(false)
    })
  }

function checkErrors(formData,trimmedDataURL,inputsBuilder,responseData) {

    let areErrors = false;

    //Check NoEmpty AND SELECT
    map(inputsBuilder, item => {
        
        if(!item.noCkeck) {

            if(item?.options) {

                if(formData[item.name] === item.defaultValue) {
                
                    areErrors = true;
                    
                }


            } else if(!formData[item.name]) {

                areErrors = true;
                //console.log(item.name);

            }

        }
        
    })

    //console.log(areErrors);

    
    if(
        areErrors ||
        !trimmedDataURL ||
        (!checkTz(formData.signer_tz) ) ||
        ( parseInt(formData[inputsBuilder[2].name]) > parseInt(responseData.data.docData.general.netSalary) )

        //(!checkTz(formData.partner_tz) && !formData.partner_passport) ||


        ) {
            return false;

        } else {

            return true;

        }

        

}

//https://mui.com/guides/right-to-left/#demo

const theme = createTheme({
    direction: 'rtl', // Both here and <body dir="rtl">
  });
  
  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });


function defaultValueForm(responseData,inputsBuilder) {

    const formApiData = (responseData &&  responseData?.data) ? responseData?.data?.docData : '';

    //const today = new Date();
    //const current_datetime = new Date(today.toString().replace(/-/g, '/'));

    let objReturn = {};

    map(inputsBuilder, item => {
              
        objReturn[item.name] = item.defaultValue
        
      })

    //Replace defaults values from API
    //objReturn.sex = 'זכר';
    objReturn.signer_tz = formApiData?.signer.tz ? formApiData.signer.tz : '';
    objReturn.signer_fullName = formApiData?.signer.firstName + ' ' + formApiData?.signer.lastName;
    objReturn.signer_address = formApiData?.signer.address;
    objReturn.money = formApiData?.general.money;
    
    
    //objReturn.date_birth = formApiData?.signer.date_birth ? convertDBDate(formApiData.signer.date_birth) : current_datetime;

    return objReturn;

   
}


