import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/animateNew.css"; /* https://daneden.github.io/animate.css/ */

import "react-toastify/dist/ReactToastify.css";

import "./css/site.scss";
import "./css/site_pages.scss";
import "./css/mobile.scss";

import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect
//npm i md5 react-device-detect react-router-dom react-scroll react-toastify sass lodash react-idle-timer validator

//https://mui.com/getting-started/installation/
//npm install @mui/material @emotion/react @emotion/styled  @mui/icons-material

import { ToastContainer } from "react-toastify"; //npm i react-toastify

//import LoginPage from "./Components/controllers/loginRouter";
import GetPage from "./Components/controllers/getPageRouter";

export default function App() {
 
  return (
    <>
          <GetPage />
      {/* 
      {localStorage.getItem("userData") &&
      localStorage.getItem("userData").length > 0 ? (
        <GetPage />
      ) : (
        <LoginPage />
      )} */}

      <ToastContainer
        position={isMobile ? "top-center" : "top-left"}
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={true}
        pauseOnVisibilityChange
        draggable
        pauseOnHover={true}
        theme="colored"
      />
    </>
  );
}

//export default App;
