import React from 'react'

import './FormsViewIndex.scss'
import DefaultPage from './DefaultPage';

import Form101 from './101/Form101'
import Form01 from './01/Form01'
import Form02a from './02a/Form02a'

import Form03 from './03/Form03'
import Form04 from './04/Form04'
import Form05 from './05/Form05'


//import Form161 from './161/Form161'

export default function FormsViewIndex(props) {

  const {page} = props;

  //console.log(page);

  switch (page) {

    case "101":
      return (
        <React.Fragment>
          <Form101 {...props} />
        </React.Fragment>
      )

    case "01":
      return (
        <React.Fragment>
          <Form01 {...props} />
        </React.Fragment>
      )

    case "02a":
    return (
      <React.Fragment>
        <Form02a {...props} />
      </React.Fragment>
    )

    case "03":
    return (
      <React.Fragment>
        <Form03 {...props} />
      </React.Fragment>
    )

    case "04":
    return (
      <React.Fragment>
        <Form04 {...props} />
      </React.Fragment>
    )


    case "05":
    return (
      <React.Fragment>
        <Form05 {...props} />
      </React.Fragment>
    )


     

  

    default:
      return (
        <React.Fragment>
          <DefaultPage {...props} />
        </React.Fragment>
      );
  }
  
}
