//const today = new Date();
//const current_datetime = new Date(today.toString().replace(/-/g, '/'));

export const filesCategories = [
    {
        id: "1",
        name: "161 עובד"
    },
    {
        id: "2",
        name: "מכתב התפטרות/פיטורים"
    },
    {
        id: "3",
        name: "לעובדים זרים שטסים - יומני עבודה"
    },
    {
        id: "4",
        name: "בהתפטרות בשל מצב רפואי - מכתב מרופא תעסוקתי"
    },
    {
        id: "5",
        name: "בפיטורים - זימון לשימוע, פרוטוקול שימוע ומכתב פיטורים"
    },
   
];

export const inputsBuilder = [

    {
        'name' : 'signer_tz',
        'label' : 'תעודת זהות',
        'type' : 'number',
        'defaultValue': '',
        
        
    },
    {
        'name' : 'signer_fullName',
        'label' : 'שם מלא',
        'defaultValue': ''
    },

    {
        'name' : 'reason',
        'label' : 'סיבת הפיצויים',
        'options' : [
                    'התפטרות',
                    'פיטורים'
                    //'',
                    //'',
                ],
        'defaultValue': 'סיבת הפיצויים',
        //'noCkeck' : true
    },

    {
        'name' : 'notes',
        'label' : 'הערות',
        'defaultValue': '',
        'noCkeck' : true
    },
    

]


/* 

signer_address: '',
        signer_passport: '',
        date_birth: formApiData?.signer.date_birth ? convertDBDate(formApiData.signer.date_birth) : current_datetime,
        date_aliya: current_datetime,
        sex: 'מין', */