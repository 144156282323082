import { RestUrls } from "./config";
import { toast } from 'react-toastify' //npm i react-toastify


export default async function getDataFromApi (url,objectToSend, controller, auth = 'all' ) {

    //const API =  RestUrls.baseApiUrl + controller + '/' + url;
    
    if(url) {
        url = '/' + url;
    }

    const API =  RestUrls.baseApiUrl + controller + url;

    //console.log(API);

  
    let userPass = {};

    let items = {...userPass, ...objectToSend}

    try {

        
        const res = await fetch(API, {
            method: "POST",
            headers: {
            'Authorization': 'Bearer ' + userPass.token,
            "Content-Type" : 'application/json',
            "Accept" : 'application/json'
            },
            body: JSON.stringify(items)
        });

        
        if(res.status === 401) { //no token
            const error = { 'error' : 'שגיאה' };
            return error;
        }

        const data = await res.json();

        if(data) {

            let statusHeaders = {
                headerStatus : res.status
            };

            let responseData = {...statusHeaders, ...data}
            //console.log(res);
            //console.log(data);
            return responseData;
        } else {

            const error = { 'error' : 'שגיאה' };
            return error;

        }

    } catch (e) {
        console.log(e);
        toast.error('שגיאה בשרת');
        return {};
        
    }

}



export function sendtoAdmin(url,controller,objectToSend, setStateName, setLoading, auth = 'all',  ) {

    setLoading(true);
    const getData = getDataFromApi(url,objectToSend,controller,auth);

    getData.then( (getData) => {

        //console.log(getData);
        setStateName(getData)
        
    })

    getData.catch((error) => {
        console.log(error);
    })

    getData.finally(() => {
        setLoading(false);
    })

}