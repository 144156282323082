import React from "react";

import FormsComposer from './../../layaouts/FormsComposer'
//import UserViewComposer from "../../layaouts/UserViewComposer";
//import NurseViewComposer from '../views/NurseViewComposer'

export default function SiteConnector(props) {

  const { router } = props;

  if (router === "router1") {
  
    //open pages on UserIndex
    return <FormsComposer {...props} />;
  
  } else if (router === "router2") {
    
    return <h2>ROUTER 2</h2>;
    /* return <NurseViewComposer {...props} /> */

  } else if (router === "logout") {
    
    localStorage.setItem("userData", "");
    window.open("/", "_self");
    return <p>טוען...</p>;

  }
}
