import React from 'react'

export default function Footer() {
  return (
    <div className="credit">
        <a href="https://waveproject.co.il/" target="_blank" rel="noopener noreferrer">
            <img src="https://leads.wdev.co.il/commonAssetsWave/credit/wp-b-hd.png" alt="WaveProject" />
        </a>
    </div>
  )
}
