import React, { useState } from 'react'
import {Card} from 'react-bootstrap'

import Loading from './../../../Components/utils/Loading'
import { sendtoAdmin } from '../../../Components/api/getDataFromApi';
import FormFields from './FormFields';

import RtlMaterialCont from '../../../Components/utils/RtlMaterialCont';

export default function Form101(props) {

    const {search} = props;

    const [responseData, setResponseData] = useState(false);
    const [loading, setLoading] = useState(false);

    if(!loading && !responseData && (search.id && search.token) ) {

        const send = {
             id : search.id,
             token: search.token,
             reactView: true
            };

        sendtoAdmin('getFormData','DocData', send ,setResponseData,setLoading);
        //setResponseData(true);
    }

    if( (!search.id || !search.token) || responseData?.error) {

        return (
            <div className='form101'>
                
                <Card className='defaultCard'>
                    <Card.Header >
                        <h1 className='error'>הקישור לא תקין</h1>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title>נא לבדוק את הקישור</Card.Title>
                    </Card.Body>
                </Card>

            </div>
        )

    }

   
  return (
    
    <div className='form101'>

        <Loading open={loading} />

        { (responseData && !responseData.error ) && <Card className='defaultCard'>
            
            <Card.Header >
                <h1>כרטיס עובד</h1>
                <p>טופס 101</p>
            </Card.Header>
            <Card.Body>
                <RtlMaterialCont content={ <FormFields responseData = {responseData} search = {search} /> } />
            </Card.Body>
        </Card> }

    </div>
  )
}

