import React, { useState } from 'react'

import TextField from '@mui/material/TextField';

import {checkTz, convertDateToDisplay, convertDBDate} from '../../../Components/utils/helpFunctions'

import SignatureCanvas from 'react-signature-canvas';

import FormControl from '@material-ui/core/FormControl';
//import Paper from '@material-ui/core/Paper';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import Childrens from './Childrens';
import Taxes from './Taxes';

import AddReactionSharpIcon from '@mui/icons-material/AddReactionSharp';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

import {Button, Spinner } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0

import validator from "validator"; //npm install validator
import { toast } from 'react-toastify' //npm i react-toastify

import getDataFromApi from './../../../Components/api/getDataFromApi'

import { inputsBuilder, filesCategories } from './inputsBuilder';

import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';

import { Accordion } from 'react-bootstrap';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css?v=6";

/* https://www.npmjs.com/package/react-datepicker */
/* ALL PROPS */
/* https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md */

import { map } from "lodash" //https://lodash.com/docs/4.17.15#map

import AddFiles from './AddFiles';

import { registerLocale } from  "react-datepicker";
import he from 'date-fns/locale/he';
registerLocale('he', he)




export default function FormFields(props) {

    const {responseData, search} = props;
    
    const [formData, setFormData] = useState(defaultValueForm(responseData,inputsBuilder));
    const [showErrors, setShowErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const [childCount, setChildCount] = useState(0);
    const [taxesCount, setTaxesCount] = useState(0);

    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(false);

    const formApiData = (responseData &&  responseData?.data) ? responseData?.data?.docData : '';

    //console.log(formData);

    const onChange = (e) => {

        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        })

    }

    const onChangeCheckbox = (e) => {

        const changeValue = !formData[e.target.name];

        setFormData({
            ...formData,
            [e.target.name]: changeValue,
          })

    }

    
    const addChildrens = (children) => {

        //console.log(children);

        if(!formData[children.idChild] || formData[children.idChild] !== children) {

            if(children.name &&  children.tz &&  children.birthday) {

                setFormData({
                    ...formData,
                    [children.idChild]: children
                })

            }

        }

    }

    const addTaxes = (taxes) => {

        if(!formData[taxes.idChild] || formData[taxes.idChild] !== taxes) {

            if(taxes.name) {

                setFormData({
                    ...formData,
                    [taxes.idChild]: taxes
                })

            }

        }

    }

    //For datePicker
    const handleChangeDate = (date,nameInput) => {

        setFormData({
            ...formData,
            [nameInput]: date,
          })
        
    }

   
    const sendForm = () => { 

        
        setShowErrors(true);

        if(!checkErrors(formData,trimmedDataURL,inputsBuilder)) {

            toast.error('נא למלא את השדות  חובה');

        } else {

            const sendObj = {
                id : search.id,
                token: search.token,
            }

            sendObj.formData = formData;
            sendObj.sign = trimmedDataURL;

            sendObj.formData.date_birth2 = convertDateToDisplay(sendObj.formData.date_birth);
            sendObj.formData.date_aliya2 = convertDateToDisplay(sendObj.formData.date_aliya);

            sendObj.formData.income_date2 = convertDateToDisplay(sendObj.formData.income_date);
            sendObj.formData.partner_date_aliya2 = convertDateToDisplay(sendObj.formData.partner_date_aliya);
            sendObj.formData.partner_date_birth2 = convertDateToDisplay(sendObj.formData.partner_date_birth);
            sendObj.formData.mas_exceptions14_date12 = convertDateToDisplay(sendObj.formData.mas_exceptions14_date1);
            sendObj.formData.mas_exceptions3_date2 = convertDateToDisplay(sendObj.formData.mas_exceptions3_date);
            sendObj.formData.mas_exceptions4_date11 = convertDateToDisplay(sendObj.formData.mas_exceptions4_date);
            sendObj.formData.mas_exceptions4_date22 = convertDateToDisplay(sendObj.formData.mas_exceptions4_date2);
            sendObj.formData.mas_exceptions14_date22 = convertDateToDisplay(sendObj.formData.mas_exceptions14_date2);

            //console.log(sendObj);

            sendtoApi('saveFormData', 'DocData', sendObj, setLoading, setPdfUrl);

        }
      

    }

    const endSign = () => {

        setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL('image/png'));
        //console.log('end');

    }

    const onBegin = () => {

        //console.log('onBegin');
    }

    let sigPad = trimmedDataURL;

    const clear = () => {
        setTrimmedDataURL(null);
        sigPad.clear(sigPad);
    }

    //console.log('inputsBuilder', inputsBuilder);

    function addChildrensComp (childCount)  { 

        let allChildrens = [];

       for (let index = 0; index < childCount; index++) {
            const idChild = index+1;
            allChildrens.push(<Childrens key={index} showErrors = {showErrors} idChild={idChild} addChildrens = { (data)=> addChildrens(data) } />)
        } 

        return allChildrens;

    }

    function addTaxesComp (taxesCount)  { 

        let allTaxes = [];

       for (let index = 0; index < taxesCount; index++) {
            const idChild = index+1;
            allTaxes.push(<Taxes key={index} showErrors = {showErrors} idChild={idChild} addTaxes = { (data)=> addTaxes(data) } />)
        } 

        return allTaxes;

    }

    let childrens = addChildrensComp(childCount);

    let taxes = addTaxesComp(taxesCount);

  return (
    <div className='formFields' >

            <header>
                <p>שנת המס: <strong>{formApiData.general.masYear}</strong></p>
            </header>
            
                <form noValidate autoComplete="off">

                    {/* https://v4.mui.com/components/text-fields/ */}

                    <div className='titles'>
                        <h2>פרטי העובד</h2>
                        {/* <p>יש לצרף...</p> */}
                    </div>

                    <FormControl >

                        <TextField 
                            label={inputsBuilder[0].label}
                            value={formData[inputsBuilder[0].name]}
                            name={inputsBuilder[0].name}
                            onChange={onChange}
                            type={inputsBuilder[0].type}
                            error={ showErrors && (!checkTz(formData[inputsBuilder[0].name]) && !formData[inputsBuilder[1].name])}
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <FormControl >

                        <TextField 
                            type={inputsBuilder[1].type}
                            label={inputsBuilder[1].label}
                            value={formData[inputsBuilder[1].name]}
                            name={inputsBuilder[1].name}
                            onChange={onChange}
                            error={ showErrors && (!formData[inputsBuilder[1].name] && !checkTz(formData[inputsBuilder[0].name]))}
                            helperText={inputsBuilder[1].helperText}
                            />

                    </FormControl>

                    <FormControl >

                        <TextField 
                            label={inputsBuilder[2].label}
                            value={formData[inputsBuilder[2].name]}
                            name={inputsBuilder[2].name}
                            onChange={onChange}
                            error={ showErrors && !formData[inputsBuilder[2].name]}
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <FormControl >

                        <TextField 
                            label={inputsBuilder[3].label}
                            value={formData[inputsBuilder[3].name]}
                            name={inputsBuilder[3].name}
                            onChange={onChange}
                            error={ showErrors && !formData[inputsBuilder[3].name]}
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <FormControl className='dateWrapper'>
                        <div className='titleDate'>{inputsBuilder[4].label}</div>
                        <DatePicker 
                            showYearDropdown = {true}
                            selected={formData[inputsBuilder[4].name]}
                            dateFormat='dd/MM/yy'
                            locale="he"
                            onChange={(date)=>handleChangeDate(date, inputsBuilder[4].name)}
                            //disabled={this.props.is_disabled ? true : false}
                        />

                    </FormControl>

                    <FormControl className='dateWrapper'>
                        <div className='titleDate'>{inputsBuilder[5].label}</div>

                        <DatePicker 
                            showYearDropdown = {true}
                            selected={formData[inputsBuilder[5].name]}
                            dateFormat='dd/MM/yy'
                            locale="he"
                            onChange={(date)=>handleChangeDate(date, inputsBuilder[5].name)}
                            //disabled={this.props.is_disabled ? true : false}
                        />

                    </FormControl>

                    <FormControl >

                        <TextField 
                            multiline
                            /* maxRows={4} */
                            label={inputsBuilder[6].label}
                            value={formData[inputsBuilder[6].name]}
                            name={inputsBuilder[6].name}
                            onChange={onChange}
                            error={ showErrors && !formData[inputsBuilder[6].name] }
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <FormControl >

                        <TextField 
                            //multiline
                            /* maxRows={4} */
                            type='number'
                            label={inputsBuilder[7].label}
                            value={formData[inputsBuilder[7].name]}
                            name={inputsBuilder[7].name}
                            onChange={onChange}
                            error={ showErrors && !formData[inputsBuilder[7].name] }
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <FormControl >

                        <TextField 
                            //multiline
                            /* maxRows={4} */
                            //type='number'
                            label={inputsBuilder[8].label}
                            value={formData[inputsBuilder[8].name]}
                            name={inputsBuilder[8].name}
                            onChange={onChange}
                            error={ showErrors && !formData[inputsBuilder[8].name] }
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <FormControl >

                        <TextField 
                            //multiline
                            /* maxRows={4} */
                            //type='number'
                            label={inputsBuilder[9].label}
                            value={formData[inputsBuilder[9].name]}
                            name={inputsBuilder[9].name}
                            onChange={onChange}
                            error={ showErrors && !formData[inputsBuilder[9].name] }
                            //helperText="שגיאה"
                            />

                    </FormControl>

                   
                    <FormControl  className='selectInput'>
                        <Select
                           
                            value={formData[inputsBuilder[10].name]}
                            name={inputsBuilder[10].name}
                            onChange={onChange}
                            label={inputsBuilder[10].label}
                            error={showErrors && (formData[inputsBuilder[10].name] === inputsBuilder[10].defaultValue )} >
                            
                            <MenuItem value={inputsBuilder[10].label}>בחר/י {inputsBuilder[10].label}...</MenuItem>
                            {
                                map(inputsBuilder[10].options, item => {

                                    if(true) {
                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                    } else return false })
                            }
                        </Select>
                    </FormControl>

                    <FormControl  className='selectInput'>
                        <Select
                           
                            value={formData[inputsBuilder[11].name]}
                            name={inputsBuilder[11].name}
                            onChange={onChange}
                            label={inputsBuilder[11].label}
                            error={showErrors && (formData[inputsBuilder[11].name] === inputsBuilder[11].defaultValue )} >
                            
                            <MenuItem value={inputsBuilder[11].label}>בחר/י {inputsBuilder[11].label}...</MenuItem>
                            {
                                map(inputsBuilder[11].options, item => {

                                    if(true) {
                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                    } else return false })
                            }
                        </Select>
                    </FormControl>

                    <FormControl  className='selectInput'>
                        <Select
                           
                            value={formData[inputsBuilder[12].name]}
                            name={inputsBuilder[12].name}
                            onChange={onChange}
                            label={inputsBuilder[12].label}
                            error={showErrors && (formData[inputsBuilder[12].name] === inputsBuilder[12].defaultValue )} >
                            
                            <MenuItem value={inputsBuilder[12].label}>בחר/י {inputsBuilder[12].label}...</MenuItem>

                            {
                                map(inputsBuilder[12].options, item => {

                                    if(true) {
                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                    } else return false })
                            }
                        </Select>
                    </FormControl>

                    <FormControl  className='selectInput'>
                        <Select
                           
                            value={formData[inputsBuilder[13].name]}
                            name={inputsBuilder[13].name}
                            onChange={onChange}
                            label={inputsBuilder[13].label}
                            error={showErrors && (formData[inputsBuilder[13].name] === inputsBuilder[13].defaultValue )} >
                            
                            <MenuItem value={inputsBuilder[13].label}>בחר/י {inputsBuilder[13].label}...</MenuItem>

                            {
                                map(inputsBuilder[13].options, item => {

                                    if(true) {
                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                    } else return false })
                            }
                        </Select>
                    </FormControl>

                    <FormControl  className='selectInput'>
                        <Select
                           
                            value={formData[inputsBuilder[14].name]}
                            name={inputsBuilder[14].name}
                            onChange={onChange}
                            label={inputsBuilder[14].label}
                            error={showErrors && (formData[inputsBuilder[14].name] === inputsBuilder[14].defaultValue )} >
                            
                            <MenuItem value={inputsBuilder[14].label}>בחר/י {inputsBuilder[14].label}...</MenuItem>

                            {
                                map(inputsBuilder[14].options, item => {

                                    if(true) {
                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                    } else return false })
                            }
                        </Select>
                    </FormControl>

                    {formData.kupatHolim === 'כן' && <FormControl >

                        <TextField 
                            //multiline
                            /* maxRows={4} */
                            //type='number'
                            label={inputsBuilder[15].label}
                            value={formData[inputsBuilder[15].name]}
                            name={inputsBuilder[15].name}
                            onChange={onChange}
                            error={ showErrors && (formData.kupatHolim === 'כן' && !formData[inputsBuilder[15].name]) }
                            //helperText="שגיאה"
                            />

                    </FormControl> }

                    <FormControl >

                        <TextField 
                            //multiline
                            /* maxRows={4} */
                            //type='number'
                            label={inputsBuilder[16].label}
                            value={formData[inputsBuilder[16].name]}
                            name={inputsBuilder[16].name}
                            onChange={onChange}
                            error={ showErrors && !validator.isEmail(formData[inputsBuilder[16].name]) }
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <FormControl >

                        <TextField 
                            //multiline
                            /* maxRows={4} */
                            type='number'
                            label={inputsBuilder[17].label}
                            value={formData[inputsBuilder[17].name]}
                            name={inputsBuilder[17].name}
                            onChange={onChange}
                            //error={ showErrors && !formData[inputsBuilder[17].name] }
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <FormControl >

                        <TextField 
                            //multiline
                            /* maxRows={4} */
                            type='number'
                            label={inputsBuilder[18].label}
                            value={formData[inputsBuilder[18].name]}
                            name={inputsBuilder[18].name}
                            onChange={onChange}
                            error={ showErrors && !formData[inputsBuilder[18].name] }
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <div className='titles'>
                        <h2>פרטים על ילדיי שבשנת המס טרם מלאו להם 19 שנה</h2>
                        <p>(עפ”י ספח תעודת זהות)</p>
                    </div>
                    
                    <div className='childrens'>
                        
                        {childrens}
                        
                        {childCount < 14 && <div style={{textAlign: 'left', padding: '10px 0 0 0'}}>
                            <Button size="sm" onClick = { ()=> setChildCount( childCount + 1) } >
                                <AddReactionSharpIcon />{' '}
                                <span>הוספת ילד/ה </span>
                            </Button>
                        </div> }
                        
                    </div>

                    <div className='titles'>
                        <h2>פרטים על הכנסותיי ממעביד זה</h2>
                        {/* <p>יש לצרף...</p> */}
                    </div>

                    <FormControl  className='selectInput'>
                        <Select
                           
                            value={formData[inputsBuilder[19].name]}
                            name={inputsBuilder[19].name}
                            onChange={onChange}
                            label={inputsBuilder[19].label}
                            error={showErrors && (formData[inputsBuilder[19].name] === inputsBuilder[19].defaultValue )} >
                            
                            <MenuItem value={inputsBuilder[19].label}>בחר/י {inputsBuilder[19].label}...</MenuItem>

                            {
                                map(inputsBuilder[19].options, item => {

                                    if(true) {
                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                    } else return false })
                            }
                        </Select>
                    </FormControl>

                    <FormControl className='dateWrapper'>
                        <div className='titleDate'>{inputsBuilder[20].label}</div>
                        <DatePicker 
                            showYearDropdown = {true}
                            selected={formData[inputsBuilder[20].name]}
                            dateFormat='dd/MM/yy'
                            locale="he"
                            onChange={(date)=>handleChangeDate(date, inputsBuilder[20].name)}
                            //disabled={this.props.is_disabled ? true : false}
                        />

                    </FormControl>

                    <div className='titles'>
                        <h2>פרטים על הכנסות אחרות</h2>
                        {/* <p>יש לצרף...</p> */}
                    </div>

                    <FormControl  className='selectInput'>
                        <Select
                           
                            value={formData[inputsBuilder[21].name]}
                            name={inputsBuilder[21].name}
                            onChange={onChange}
                            label={inputsBuilder[21].label}
                            error={showErrors && (formData[inputsBuilder[21].name] === inputsBuilder[21].defaultValue )} >
                            
                            <MenuItem value={inputsBuilder[21].label}>בחר/י {inputsBuilder[21].label}...</MenuItem>

                            {
                                map(inputsBuilder[21].options, item => {

                                    if(true) {
                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                    } else return false })
                            }
                        </Select>
                    </FormControl>


                    {formData.have_another_income === 'יש לי הכנסות אחרות' &&
                    
                        <>
                            <FormControl  className='selectInput'>
                                <Select
                                
                                    value={formData[inputsBuilder[22].name]}
                                    name={inputsBuilder[22].name}
                                    onChange={onChange}
                                    label={inputsBuilder[22].label}
                                    error={showErrors && (formData[inputsBuilder[22].name] === inputsBuilder[22].defaultValue )} >
                                    
                                    <MenuItem value={inputsBuilder[22].label}>בחר/י {inputsBuilder[22].label}...</MenuItem>

                                    {
                                        map(inputsBuilder[22].options, item => {

                                            if(true) {
                                                return <MenuItem key={item} value={item}>{item}</MenuItem>
                                            } else return false })
                                    }
                                </Select>
                            </FormControl>

                            <FormControl  className='selectInput'>
                                <Select
                                
                                    value={formData[inputsBuilder[23].name]}
                                    name={inputsBuilder[23].name}
                                    onChange={onChange}
                                    label={inputsBuilder[23].label}
                                    error={showErrors && (formData[inputsBuilder[23].name] === inputsBuilder[23].defaultValue )} >
                                    
                                    <MenuItem value={inputsBuilder[23].label}>בחר/י {inputsBuilder[23].label}...</MenuItem>

                                    {
                                        map(inputsBuilder[23].options, item => {

                                            if(true) {
                                                return <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
                                            } else return false })
                                    }
                                </Select>
                            </FormControl>
                        </>
                        }

                    <div className='titles'>
                        <h2>פרטים על בן/בת הזוג</h2>
                        {/* <p>יש לצרף...</p> */}
                    </div>
                    
                    <FormControl >

                    <TextField 
                        label={inputsBuilder[24].label}
                        value={formData[inputsBuilder[24].name]}
                        name={inputsBuilder[24].name}
                        onChange={onChange}
                        type={inputsBuilder[24].type}
                        //error={ showErrors && (!checkTz(formData[inputsBuilder[24].name]) && !formData[inputsBuilder[25].name])}
                        //helperText="שגיאה"
                        />

                    </FormControl>

                    <FormControl >

                    <TextField 
                        type={inputsBuilder[25].type}
                        label={inputsBuilder[25].label}
                        value={formData[inputsBuilder[25].name]}
                        name={inputsBuilder[25].name}
                        onChange={onChange}
                        //error={ showErrors && (!formData[inputsBuilder[25].name] && !checkTz(formData[inputsBuilder[24].name]))}
                        helperText={inputsBuilder[25].helperText}
                        />

                    </FormControl>

                    <FormControl >

                        <TextField 
                            label={inputsBuilder[26].label}
                            value={formData[inputsBuilder[26].name]}
                            name={inputsBuilder[26].name}
                            onChange={onChange}
                            //error={ showErrors && !formData[inputsBuilder[26].name]}
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <FormControl >

                        <TextField 
                            label={inputsBuilder[27].label}
                            value={formData[inputsBuilder[27].name]}
                            name={inputsBuilder[27].name}
                            onChange={onChange}
                            //error={ showErrors && !formData[inputsBuilder[27].name]}
                            //helperText="שגיאה"
                            />

                    </FormControl>

                    <FormControl className='dateWrapper'>
                        <div className='titleDate'>{inputsBuilder[28].label}</div>
                        <DatePicker 
                            showYearDropdown = {true}
                            selected={formData[inputsBuilder[28].name]}
                            dateFormat='dd/MM/yy'
                            locale="he"
                            onChange={(date)=>handleChangeDate(date, inputsBuilder[28].name)}
                            //disabled={this.props.is_disabled ? true : false}
                        />

                    </FormControl>

                    <FormControl className='dateWrapper'>
                        <div className='titleDate'>{inputsBuilder[29].label}</div>

                        <DatePicker 
                            showYearDropdown = {true}
                            selected={formData[inputsBuilder[29].name]}
                            dateFormat='dd/MM/yy'
                            locale="he"
                            onChange={(date)=>handleChangeDate(date, inputsBuilder[29].name)}
                            //disabled={this.props.is_disabled ? true : false}
                        />

                    </FormControl>
                    
                    <FormControl  className='selectInput'>
                        <Select
                           
                            value={formData[inputsBuilder[30].name]}
                            name={inputsBuilder[30].name}
                            onChange={onChange}
                            label={inputsBuilder[30].label}
                            error={showErrors && (formData[inputsBuilder[30].name] === inputsBuilder[30].defaultValue )} >
                            
                            <MenuItem value={inputsBuilder[30].label}>בחר/י {inputsBuilder[30].label}...</MenuItem>

                            {
                                map(inputsBuilder[30].options, item => {

                                    if(true) {
                                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                                    } else return false })
                            }
                        </Select>
                    </FormControl>
                   

                    <div className='accordionSec'>

                        {/* defaultActiveKey="accordion0" */}
                        <Accordion className="accordions acc1"   >

                            <Accordion.Item eventKey="accordion0"  >
                                
                                <Accordion.Header>
                                    <div className='titles'>
                                        <h2>אני מבקש/ת פטור או זיכוי ממס מהסיבות הבאות:</h2>
                                        {/* <p>יש לצרף...</p> */}
                                    </div>
                                </Accordion.Header>

                                <Accordion.Body>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={formData[inputsBuilder[31].name] ? true : false}
                                        onChange={onChangeCheckbox} name={inputsBuilder[31].name}
                                        color="primary" />}
                                        label={inputsBuilder[31].label}
                                    />
                                        
                                    </FormGroup>

                                    <Divider />

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[32].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[32].name}
                                            color="primary" />}
                                            label={inputsBuilder[32].label}
                                        />
                                        <p className='smallPText'>מצורף אישור משרד הביטחון/האוצר/פקיד השומה/תעודת עיוור שהוצאה לאחר 94.1.1.
אם לא סומן בפרק ה כי "אין לי הכנסות אחרות לרבות מלגות" העובד יופנה לפקיד השומה לעריכת תיאום מס.</p>
                                    </FormGroup>

                                    <Divider />

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[33].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[33].name}
                                            color="primary" />}
                                            label={inputsBuilder[33].label}
                                        />
                                    </FormGroup>

                                    <FormControl className='dateWrapper'>
                                        <div className='titleDate'>{inputsBuilder[34].label}</div>

                                        <DatePicker 
                                            showYearDropdown = {true}
                                            selected={formData[inputsBuilder[34].name]}
                                            dateFormat='dd/MM/yy'
                                            locale="he"
                                            onChange={(date)=>handleChangeDate(date, inputsBuilder[34].name)}
                                            //disabled={this.props.is_disabled ? true : false}
                                        />

                                    </FormControl>

                                    <p className='smallPText'>אני ובני משפחתי מדרגה ראשונה, אני תושב/ת קבוע/ה בישוב מזכה מתגוררים ביישוב:</p>

                                    <FormControl >

                                        <TextField 
                                            label={inputsBuilder[35].label}
                                            value={formData[inputsBuilder[35].name]}
                                            name={inputsBuilder[35].name}
                                            onChange={onChange}
                                            //error={ showErrors && !formData[inputsBuilder[35].name]}
                                            //helperText="שגיאה"
                                            />

                                    </FormControl>

                                    <p className='smallPText'>ואין לי "מרכז חיים" נוסף. מצורף אישור של הרשות ע"ג טופס 1312א.</p>

                                    <Divider />

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[36].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[36].name}
                                            color="primary" />}
                                            label={inputsBuilder[36].label}
                                        />
                                        
                                    </FormGroup>

                                    <FormControl className='dateWrapper'>
                                        <div className='titleDate'>{inputsBuilder[37].label}</div>

                                        <DatePicker 
                                            showYearDropdown = {true}
                                            selected={formData[inputsBuilder[37].name]}
                                            dateFormat='dd/MM/yy'
                                            locale="he"
                                            onChange={(date)=>handleChangeDate(date, inputsBuilder[37].name)}
                                            //disabled={this.props.is_disabled ? true : false}
                                        />

                                    </FormControl>

                                    <p className='smallPText'>לא היתה לי הכנסה בישראל מתחילת שנת המס הנוכחית עד תאריך: </p>
                                    <FormControl className='dateWrapper'>
                                        <div className='titleDate'>{inputsBuilder[57].label}</div>

                                        <DatePicker 
                                            showYearDropdown = {true}
                                            selected={formData[inputsBuilder[57].name]}
                                            dateFormat='dd/MM/yy'
                                            locale="he"
                                            onChange={(date)=>handleChangeDate(date, inputsBuilder[57].name)}
                                            //disabled={this.props.is_disabled ? true : false}
                                        />

                                    </FormControl>
                                    <p className='smallPText'>מי שתקופת זכאותו (42 חודש) אינה רצופה בשל שירות חובה בצה"ל, לימודים על תיכוניים או יציאה לחו"ל - יפנה לפקיד השומה. חובה לצרף: תעודת עולה.</p>
                                    <Divider />

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[38].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[38].name}
                                            color="primary" />}
                                            label={inputsBuilder[38].label}
                                        />
                                        <p className='smallPText'>בגין בן/בת זוגי המתגורר/ת עימי ואין לו/לה הכנסות בשנת המס.</p>
                                        <p className='smallPText'>רק אם העובד/ת או בן/בת הזוג הגיע/ה לגיל פרישה או שהוא/היא נכה או עיוור/ת עפ"י סעיף 9)5 (לפקודה.</p>
                                    </FormGroup>

                                    <Divider />

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[39].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[39].name}
                                            color="primary" />}
                                            label={inputsBuilder[39].label}
                                        />
                                        
                                    </FormGroup>

                                    <p className='smallPText'>החי בנפרד. ימולא רק ע"י הורה כאמור החי בנפרד ומבקש נקודות זיכוי עבור ילדיו, הנמצאים בחזקתו ובגינם o אני הורה במשפחה חד הורית מקבל קצבת ילדים מהמוסד לביטוח לאומי (בהתאם לסעיף 7 להלן) ואינו מנהל משק בית משותף עם יחיד/ה אחר/ת.</p>

                                    <Divider />

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[40].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[40].name}
                                            color="primary" />}
                                            label={inputsBuilder[40].label}
                                        />
                                    </FormGroup>

                                    <p className='smallPText'>המפורטים בחלק ג ימולא רק ע"י הורה במשפחה חד הורית שמקבל את קצבת הילדים בגינם, או ע"י אשה נשואה או ע"י הורה יחיד</p>

                                    <FormControl >

                                        <TextField 
                                            label={inputsBuilder[41].label}
                                            value={formData[inputsBuilder[41].name]}
                                            name={inputsBuilder[41].name}
                                            onChange={onChange}
                                            //error={ showErrors && !formData[inputsBuilder[35].name]}
                                            //helperText="שגיאה"
                                            />

                                    </FormControl>

                                    <FormControl >

                                        <TextField 
                                            label={inputsBuilder[42].label}
                                            value={formData[inputsBuilder[42].name]}
                                            name={inputsBuilder[42].name}
                                            onChange={onChange}
                                            //error={ showErrors && !formData[inputsBuilder[35].name]}
                                            //helperText="שגיאה"
                                            />

                                    </FormControl>
                                    
                                    <FormControl >

                                        <TextField 
                                            label={inputsBuilder[43].label}
                                            value={formData[inputsBuilder[43].name]}
                                            name={inputsBuilder[43].name}
                                            onChange={onChange}
                                            //error={ showErrors && !formData[inputsBuilder[35].name]}
                                            //helperText="שגיאה"
                                            />

                                    </FormControl>

                                    <FormControl >

                                        <TextField 
                                            label={inputsBuilder[44].label}
                                            value={formData[inputsBuilder[44].name]}
                                            name={inputsBuilder[44].name}
                                            onChange={onChange}
                                            //error={ showErrors && !formData[inputsBuilder[35].name]}
                                            //helperText="שגיאה"
                                            />

                                    </FormControl>

                                    <Divider />
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[45].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[45].name}
                                            color="primary" />}
                                            label={inputsBuilder[45].label}
                                        />
                                    </FormGroup>
                                    <p className='smallPText'>ימולא ע"י הורה (למעט הורה אשר סימן בפסקה 7 לעיל), אשה לא נשואה שילדיה אינם בחזקתה וכן הורה יחיד.</p>

                                    <FormControl >

                                        <TextField 
                                            label={inputsBuilder[46].label}
                                            value={formData[inputsBuilder[46].name]}
                                            name={inputsBuilder[46].name}
                                            onChange={onChange}
                                            //error={ showErrors && !formData[inputsBuilder[35].name]}
                                            //helperText="שגיאה"
                                            />

                                    </FormControl>

                                    <FormControl >

                                        <TextField 
                                            label={inputsBuilder[47].label}
                                            value={formData[inputsBuilder[47].name]}
                                            name={inputsBuilder[47].name}
                                            onChange={onChange}
                                            //error={ showErrors && !formData[inputsBuilder[35].name]}
                                            //helperText="שגיאה"
                                            />

                                    </FormControl>

                                    <Divider />

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[48].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[48].name}
                                            color="primary" />}
                                            label={inputsBuilder[48].label}
                                        />
                                    </FormGroup>

                                    <Divider />

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[49].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[49].name}
                                            color="primary" />}
                                            label={inputsBuilder[49].label}
                                        />
                                    </FormGroup>

                                    <p className='smallPText'>המפורטים בחלק ג ואני משתתף/ת בכלכלתם. ימולא ע"י הורה החי בנפרד, שאינו זכאי לנקודות זיכוי בגין ילדיו, אשר המציא פס"ד המחייב אותו בתשלום מזונות.</p>

                                    <Divider />

                                    <br/>

                                    <FormControl >

                                        <TextField 
                                            label={inputsBuilder[50].label}
                                            value={formData[inputsBuilder[50].name]}
                                            name={inputsBuilder[50].name}
                                            onChange={onChange}
                                            //error={ showErrors && !formData[inputsBuilder[35].name]}
                                            //helperText="שגיאה"
                                            />

                                    </FormControl>

                                    <p className='smallPText'>ילדים עם מוגבלות שטרם מלאו להם 19 שנים, בגינם אני מקבל/ת גמלת ילד נכה מהמוסד לביטוח לאומי. מצורף אישור גמלת ילד נכה מהמוסד לביטוח לאומי לשנה נוכחית. בן זוגי אינו מקבל נקודות זיכוי אלה. לילדיי, בגינם אני מבקש את נקודות הזיכוי, אין הכנסות בשנה הנוכחית.</p>

                                    <Divider />

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[51].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[51].name}
                                            color="primary" />}
                                            label={inputsBuilder[51].label}
                                        />
                                    </FormGroup>

                                    <p className='smallPText'>ימולא ע"י מי שנישא בשנית. מצורף פסק דין.</p>

                                    <Divider />

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[52].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[52].name}
                                            color="primary" />}
                                            label={inputsBuilder[52].label}
                                        />
                                    </FormGroup>

                                    <Divider />

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[53].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[53].name}
                                            color="primary" />}
                                            label={inputsBuilder[53].label}
                                        />
                                        
                                    </FormGroup>
                                    
                                    <br/>

                                    <FormControl className='dateWrapper'>
                                        <div className='titleDate'>{inputsBuilder[54].label}</div>

                                        <DatePicker 
                                            showYearDropdown = {true}
                                            selected={formData[inputsBuilder[54].name]}
                                            dateFormat='dd/MM/yy'
                                            locale="he"
                                            onChange={(date)=>handleChangeDate(date, inputsBuilder[54].name)}
                                            //disabled={this.props.is_disabled ? true : false}
                                        />

                                    </FormControl>

                                    <FormControl className='dateWrapper'>
                                        <div className='titleDate'>{inputsBuilder[55].label}</div>

                                        <DatePicker 
                                            showYearDropdown = {true}
                                            selected={formData[inputsBuilder[55].name]}
                                            dateFormat='dd/MM/yy'
                                            locale="he"
                                            onChange={(date)=>handleChangeDate(date, inputsBuilder[55].name)}
                                            //disabled={this.props.is_disabled ? true : false}
                                        />

                                    </FormControl>

                                    <p  className='smallPText' >מצורף צילום של תעודת שחרור/סיום שירות.</p>


                                    <Divider />
                                    
                                    <br/>

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[56].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[56].name}
                                            color="primary" />}
                                            label={inputsBuilder[56].label}
                                        />                                        
                                    </FormGroup>

                                </Accordion.Body>

                            </Accordion.Item>

                            <Accordion.Item eventKey="accordion2"  >
                                
                                <Accordion.Header>
                                    <div className='titles'>
                                        <h2>אני מ בקש/ת תיאום מס מהסיבות הבאות:</h2>
                                        {/* <p>יש לצרף...</p> */}
                                    </div>
                                </Accordion.Header>

                                <Accordion.Body>

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[58].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[58].name}
                                            color="primary" />}
                                            label={inputsBuilder[58].label}
                                        />
                                    </FormGroup>

                                    <br/>
                                    <p className='smallPText'>הערות: 1 .יש להמציא הוכחה כגון: אישור משטרת הגבולות בגין שהייה בחו"ל, אישור מחלה וכיו"ב. בהעדר הוכחה יש לפנות לפקיד השומה. 2 .דמי לידה ודמי אבטלה הינם הכנסה חייבת.</p>

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[59].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[59].name}
                                            color="primary" />}
                                            label={inputsBuilder[59].label}
                                        />
                                    </FormGroup>

                                    {formData.taxes_2 && <div className='childrens taxes'> 
                        
                                        {taxes}
                                        
                                        {taxesCount < 4 && <div style={{textAlign: 'left', padding: '10px 0 0 0'}}>
                                            <Button size="sm" onClick = { ()=> setTaxesCount( taxesCount + 1) } >
                                                <LibraryAddIcon />{' '}
                                                <span>הוספת מעביד/משלם המשכורת </span>
                                            </Button>
                                        </div> }
                                        
                                    </div> }

                                    <Divider />

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData[inputsBuilder[60].name] ? true : false}
                                            onChange={onChangeCheckbox} name={inputsBuilder[60].name}
                                            color="primary" />}
                                            label={inputsBuilder[60].label}
                                        />
                                    </FormGroup>

                                </Accordion.Body>

                            </Accordion.Item>
                            
                        </Accordion>
                    </div>

                    <div className='signCont'>

                        <h3>חתימה</h3>

                        <div className={`canvasCont ${showErrors && !trimmedDataURL && 'error'}`}>

                            <SignatureCanvas
                                ref={(ref) => { sigPad = ref }}
                                // Pre fill sign:
                                //ref={(ref) => { ref.fromDataURL(trimmedDataURL) }}
                                onEnd={endSign}
                                onBegin={onBegin}
                                backgroundColor='white'
                                penColor='black'
                                canvasProps={{ width: 250, height: 200, className: 'sigCanvas' }}
                            />

                        </div>

                        <div className='btnCont'>
                            <button type='button' onClick={clear}>מחיקת חתימה</button>
                        </div>

                    </div>

                    <AddFiles responseData = {responseData} filesCategories = {filesCategories} />

                    <div className="d-grid gap-2 btnCont">
                        <Button className={`${checkErrors(formData,trimmedDataURL,inputsBuilder) && 'animate__animated animate__bounceIn' }`} variant={!checkErrors(formData,trimmedDataURL,inputsBuilder) ? 'danger' : 'primary'} size="lg" onClick={ sendForm }>
                            {loading ? <Spinner size="" animation="border" variant="light" /> : <span>שליחה</span> }
                        </Button>
                    </div>

                </form>
            
        {pdfUrl && <a className='pdfUrl' href={pdfUrl} target='_blank' rel="noopener noreferrer">{pdfUrl}</a>}
    </div>
  );
}


function sendtoApi(url, controller, objectToSend, setLoading, setPdfUrl) {

    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')
  
    getData.then((getData) => {

      if (getData.ok) {

        if(getData.url) {

            setPdfUrl(getData.url);

        }

        //setRequest(getData.ok)
        toast.success(getData.ok);

      } else if (getData.error) {

        toast.error(getData.error)

      }
    })
  
    getData.catch((error) => {
      console.log(error)
    })
  
    getData.finally(() => {
      setLoading(false)
    })
  }

function checkErrors(formData,trimmedDataURL,inputsBuilder) {

    let areErrors = false;

    //Check NoEmpty AND SELECT
    map(inputsBuilder, item => {
        
        if(!item.noCkeck) {

            if(item?.options) {

                if(formData[item.name] === item.defaultValue) {
                
                    areErrors = true;
                    
                }


            } else if(!formData[item.name]) {

                areErrors = true;
                //console.log(item.name);

            }

        }
        
    })

    //console.log(areErrors);

    if(
        areErrors ||
        !trimmedDataURL ||
        !formData.signer_firstName ||
        (!checkTz(formData.signer_tz) && !formData.signer_passport) ||
        //(!checkTz(formData.partner_tz) && !formData.partner_passport) ||
        (formData.kupatHolim === 'כן' && !formData.kupatHolimName) ||
        !validator.isEmail(formData.signer_email)


        ) {
            return false;

        } else {

            return true;

        }

        

}

function defaultValueForm(responseData,inputsBuilder) {

    const formApiData = (responseData &&  responseData?.data) ? responseData?.data?.docData : '';

    const today = new Date();
    const current_datetime = new Date(today.toString().replace(/-/g, '/'));

    let objReturn = {};

    map(inputsBuilder, item => {
              
        objReturn[item.name] = item.defaultValue
        
      })

    //Replace defaults values from API
    objReturn.sex = 'זכר';
    objReturn.signer_tz = formApiData?.signer.tz ? formApiData.signer.tz : '';
    objReturn.signer_firstName = formApiData?.signer.firstName ? formApiData.signer.firstName : '';
    objReturn.signer_lastName = formApiData?.signer.lastName ? formApiData.signer.lastName : '';
    objReturn.date_birth = formApiData?.signer.date_birth ? convertDBDate(formApiData.signer.date_birth) : current_datetime;

    return objReturn;

   
}


