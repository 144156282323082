import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; //npm install react-router-dom
import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect
import SiteConnector  from './SiteConnector';
import {getAllUrlParams} from "./../../Components/utils/UrlParameters";



export default function getPage() {

  let urlParams = getAllUrlParams(window.location.search);

  return (
      <React.Fragment>

            <Router>

              <Routes>
                {/* //NO FORM */}
                {/* <Route path="forms/161" element={ <SiteConnector search={ urlParams } router="router1"  page="161" isMobile = {isMobile ? true : false} />} /> */}
                
                {/* בקשה לשחזור המחאה */}
                <Route path="forms/01" element={ <SiteConnector search={ urlParams } router="router1"  page="01" isMobile = {isMobile ? true : false} />} />

                <Route path="forms/02a" element={ <SiteConnector search={ urlParams } router="router1"  page="02a" isMobile = {isMobile ? true : false} />} />
                
                <Route path="forms/03" element={ <SiteConnector search={ urlParams } router="router1"  page="03" isMobile = {isMobile ? true : false} />} />

                <Route path="forms/04" element={ <SiteConnector search={ urlParams } router="router1"  page="04" isMobile = {isMobile ? true : false} />} />

                <Route path="forms/05" element={ <SiteConnector search={ urlParams } router="router1"  page="05" isMobile = {isMobile ? true : false} />} />

                <Route path="forms/101" element={ <SiteConnector search={ urlParams } router="router1"  page="101" isMobile = {isMobile ? true : false} />} />

                {/* forms/* */}
                <Route path="*" element={ <SiteConnector search={ urlParams } router="router1" page="default" isMobile = {isMobile ? true : false} />} />
              </Routes>
              
            </Router>
            
      </React.Fragment>
  )


}
