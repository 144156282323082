
export function checkTz(str) {

    //INPUT VALIDATION

   // Just in case -> convert to string
   var IDnum = String(str);

   // Validate correct input
   if ((IDnum.length > 9) || (IDnum.length < 5))
      return false;
   if (isNaN(IDnum))
      return false;

   // The number is too short - add leading 0000
   if (IDnum.length < 9)
   {
      while(IDnum.length < 9)
      {
         IDnum = '0' + IDnum;         
      }
   }

   // CHECK THE ID NUMBER
   var mone = 0, incNum;
   for (var i=0; i < 9; i++)
   {
      incNum = Number(IDnum.charAt(i));
      incNum *= (i%2)+1;
      if (incNum > 9)
         incNum -= 9;
      mone += incNum;
   }
   if (mone%10 === 0)
      return true;
   else
      return false;

}

export function convertDate (dateValue) {

   let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX

   

   /* 2021-03-25 */

   let formatted_date = current_datetime.getFullYear() + '-' +
                       (current_datetime.getMonth() + 1) + "-" +
                       current_datetime.getDate();
                       
   return formatted_date;
}


export function convertDateToDisplay (dateValue) {

   let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX
   /* 2021-03-25 */

/*     let formatted_date = current_datetime.getFullYear() + '-' +
                       (current_datetime.getMonth() + 1) + "-" +
                       current_datetime.getDate(); */

   let formatted_date = 

         ('0' + (current_datetime.getDate())).slice(-2) + '/' +

          ('0' + (current_datetime.getMonth()+1)).slice(-2) + '/'

          
        //(current_datetime.getMonth() + 1) + "/" +
           + current_datetime.getFullYear();
                       
   return formatted_date;
}


export function convertDBDate(dbdate) {

   const returnDate = new Date(dbdate.toString().replace(/-/g, '/'));  

   return returnDate;

}
