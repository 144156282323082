import React from 'react'

import { map } from "lodash" //https://lodash.com/docs/4.17.15#map

import FileUpload from '../../../Components/uploads/FileUpload';

export default function AddFiles(props) {

    const {responseData, filesCategories} = props;

  return (
    <div className='formFields addFiles'>

        
        <h2>הוספת קבצים <small>(jpg, png, pdf)</small></h2>
        <p>נא לצרף לבקשה את המסמכים הבאים:</p>

        {
            map(filesCategories, item => {

                if(true) {
                    return <FileUpload key={item.id} item = { item } responseData = {responseData}/>
                } else return false })
        }

        

    </div>
  )
}
