import React from "react";

import { Container, Col, Row } from "react-bootstrap";
//https://react-bootstrap.netlify.app/layout/grid/#setting-column-widths-in-row

import FormsViewIndex from "../Pages/Forms/FormsViewIndex";
import Footer from "./Footer";
import {RestUrls} from "./../Components/api/config"

export default function FormsComposer(props) {

  //console.log(props);

  return <div className="body-container formsPage Desktop">
    
    <Container fluid className="animate__animated animate__fadeIn">
      <Row className="justify-content-md-center">

        <Col md='4' className="">
          <header className="topHeader">
            <img className="logo" src={RestUrls.img + 'eshLogo.png'} alt='א.ש.סיעוד מקבוצת נטלי' />
          </header>
        </Col>

      </Row>

      <Row className="justify-content-md-center">

        <Col md='4'>

          <div className='user-view'>
              <FormsViewIndex { ...props } />
          </div>

        </Col>
      </Row>

      <Row className="justify-content-md-center">

        <Col md='6'>

          <Footer />

        </Col>
      </Row>

    </Container>
    

  
  </div>;
}
