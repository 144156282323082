import React from 'react'

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading(props) {

    const {open} = props;

  return (
    <Backdrop
    
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={ open }   > {/* onClick={handleClose} */}

        <CircularProgress color="inherit" />
    </Backdrop>
  )
}
