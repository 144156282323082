//const md5 = require('md5'); //npm install md5
//let code = 'seb-webProject!nursesApp!wd+=111@$%+';

//console.log(md5(today));

//const baseApiUrl = 'https://otzarhaaretz.wdev.co.il/api/';
const appUrl = 'https://dforms.waveproject.co.il/';
const baseApiUrl1 = appUrl + 'api/';
const baseApiUrl = baseApiUrl1 + 'public/';


export const RestUrls = {
    //Code: md5(code),
    baseApiUrl : baseApiUrl,
    filesMethod : baseApiUrl + 'docData/uploadFiles',
    pictures : baseApiUrl + 'assets/img/pictures/',
    pagesPictures : baseApiUrl + 'assets/img/pages/',
    img : baseApiUrl1 + 'assets/img/',
    appUrl : appUrl
} 