const today = new Date();
const current_datetime = new Date(today.toString().replace(/-/g, '/'));

export const filesCategories = [
    {
        id: "1",
        name: "אסמכתא לפרטי חשבון בנק"
    },
    {
        id: "2",
        name: "תיאום מס"
    },
    {
        id: "3",
        name: "אישור רפואי"
    }
];

export const inputsBuilder = [

    {
        'name' : 'signer_tz',
        'label' : 'תעודת זהות',
        'type' : 'number',
        'defaultValue': '',
        'noCkeck' : true
        
    },
    {
        'name' : 'signer_passport',
        'label' : 'דרכון',
        'type' : 'number',
        'helperText' : "למי שאין מספר ת.ז",
        'defaultValue': '',
        'noCkeck' : true
        
    },
    {
        'name' : 'signer_lastName',
        'label' : 'שם משפחה',
        'defaultValue': ''
    },
    {
        'name' : 'signer_firstName',
        'label' : 'שם פרטי',
        'defaultValue': ''
    },


    {
        'name' : 'date_birth',
        'label' : 'תאריך לידה',
        'defaultValue': current_datetime
    },

    {
        'name' : 'date_aliya',
        'label' : 'תאריך עליה',
        'defaultValue': current_datetime
    },


    {
        'name' : 'signer_address',
        'label' : 'רחוב/שכונה',
        'defaultValue': ''
    },

    {
        'name' : 'signer_addressNum',
        'label' : 'מספר',
        'defaultValue': ''
    },

    {
        'name' : 'signer_addressCity',
        'label' : 'עיר/ישוב',
        'defaultValue': ''
    },

    {
        'name' : 'signer_addressMikud',
        'label' : 'מיקוד',
        'defaultValue': ''
    },


    {
        'name' : 'sex',
        'label' : 'מין',
        'options' : [
                    'זכר',
                    'נקבה'],
        'defaultValue': 'מין'
    },

    {
        'name' : 'family',
        'label' : 'מצב משפחתי',
        'options' : [
                    'רווק/ה',
                    'נשוי/אה',
                    'גרוש/ה',
                    'אלמן/ה',
                    'פרוד/ה (חובה לצרף אישור פ"ש)',
                    //'',
                ],
        'defaultValue': 'מצב משפחתי'
    },

    {
        'name' : 'citizen',
        'label' : 'תושב ישראל',
        'options' : [
                    'כן',
                    'לא'
                ],
        'defaultValue': 'תושב ישראל'
    },


    {
        'name' : 'kibutz',
        'label' : 'חבר קיבוץ/מושב שיתופי',
        'options' : [
                    'כן',
                    'לא'
                ],
        'defaultValue': 'חבר קיבוץ/מושב שיתופי'
    },


    {
        'name' : 'kupatHolim',
        'label' : 'חבר בקופת חולים',
        'options' : [
                    'כן',
                    'לא'
                ],
        'defaultValue': 'חבר בקופת חולים'
    },


    {
        'name' : 'kupatHolimName',
        'label' : 'שם בקופת חולים',
        'defaultValue': '',
        'noCkeck' : true
    },

    {
        'name' : 'signer_email',
        'label' : 'כתובת דואר אלקטרוני',
        'defaultValue': '',
        //'noCkeck' : true
    },

    {
        'name' : 'signer_phone',
        'label' : 'מספר טלפון',
        'defaultValue': '',
        'noCkeck' : true
    },

    {
        'name' : 'signer_cellPhone',
        'label' : 'מספר טלפון נייד',
        'defaultValue': '',
        //'noCkeck' : true
    },

    {
        'name' : 'income',
        'label' : 'אני מקבל/ת',
        'options' : [
                    'משכורת חודש',
                    'משכורת בעד משרה נוספת',
                    'משכורת חלקית',
                    'שכר עבודה (עובד יומי)',
                    'קצבה',
                    'מלגה',
                    //'',
                    //'',
                ],
        'defaultValue': 'אני מקבל/ת'
    },

    {
        'name' : 'income_date',
        'label' : 'תאריך תחילה העבודה בשנת המס',
        'defaultValue': current_datetime
    },


    {
        'name' : 'have_another_income',
        'label' : 'הכנסות אחרות',
        'options' : [
                        'אין לי הכנסות אחרות ממשכורת',
                        'יש לי הכנסות אחרות'
                    //'',
                    //'',
                ],
        'defaultValue': 'הכנסות אחרות'
    },

    {
        'name' : 'another_income',
        'label' : 'סוג הכנסה',
        'options' : [
                    'משכורת חודש',
                    'שכר עבודה (עובד יומי)',
                    'משכורת בעד משרה נוספת',
                    'קצבה',
                    'משכורת חלקית',
                    'מלגה',
                    //'',
                ],
        'defaultValue': 'סוג הכנסה',
        'noCkeck' : true
    },

    {
        'name' : 'another_income2',
        'label' : 'אפשרויות',
        'options' : [
                    {
                        'name' : 'אבקש לקבל נקודות זיכוי',
                        'value' : 1
                    },
                    {
                        'name' : 'אני מקבל/ת נקודות זיכוי',
                        'value' : 2
                    },
                    {
                        'name' : 'אין מפרישים עבורי לקרו השתלמות',
                        'value' : 3
                    },
                    {
                        'name' : 'אין מפרישים עבורי לקצבה/לביטוח',
                        'value' : 4
                    },
                    //'',
                ],
        'defaultValue': 'אפשרויות',
        'noCkeck' : true
    },

    {
        'name' : 'partner_tz',
        'label' : 'מספר זהות',
        'type' : 'number',
        'defaultValue': '',
        'noCkeck' : true
        
    },
    {
        'name' : 'partner_passport',
        'label' : 'דרכון',
        'type' : 'number',
        'helperText' : "למי שאין מספר ת.ז",
        'defaultValue': '',
        'noCkeck' : true
        
    },


    {
        'name' : 'partner_lastName',
        'label' : 'שם משפחה',
        'defaultValue': '',
        'noCkeck' : true
    },
    {
        'name' : 'partner_firstName',
        'label' : 'שם פרטי',
        'defaultValue': '',
        'noCkeck' : true
    },


    {
        'name' : 'partner_date_birth',
        'label' : 'תאריך לידה',
        'defaultValue': current_datetime,
        'noCkeck' : true
    },

    {
        'name' : 'partner_date_aliya',
        'label' : 'תאריך עליה',
        'defaultValue': current_datetime,
        'noCkeck' : true
    },

    {
        'name' : 'partner_income',
        'label' : 'הכנסות בן/בת הזוג',
        'options' : [
                    'אין לבן/בת הזוג כל הכנסה',
                    'יש הכנסה מ- עבודה/קצבה/עסק',
                    'יש הכנסה אחרת'
                ],
        'defaultValue': 'הכנסות בן/בת הזוג',
        'noCkeck' : true
    },

    {
        'name' : 'mas_exceptions1',
        'label' : 'אני תושב/ת ישראל.',
        'defaultValue': false,
        'noCkeck' : true
    },

    {
        'name' : 'mas_exceptions2',
        'label' : 'אני נכה 100% / עיוור/ת לצמיתות...',
        'defaultValue': false,
        'noCkeck' : true
    },

    {
        'name' : 'mas_exceptions3',
        'label' : 'אני תושב/ת קבוע/ה בישוב מזכה',
        'defaultValue': false,
        'noCkeck' : true
    },

    {
        'name' : 'mas_exceptions3_date',
        'label' : 'מתאריך',
        'defaultValue': current_datetime,
        'noCkeck' : true
    },

    {
        'name' : 'mas_exceptions3_city',
        'label' : 'יישוב',
        'defaultValue': '',
        'noCkeck' : true
    },


    {
        'name' : 'mas_exceptions4',
        'label' : 'אני עולה חדש/ה',
        'defaultValue': false,
        'noCkeck' : true
    },

    {
        'name' : 'mas_exceptions4_date',
        'label' : 'מתאריך',
        'defaultValue': current_datetime,
        'noCkeck' : true
    },

    {
        'name' : 'mas_exceptions5',
        'label' : 'אין הכנסות',
        'defaultValue': false,
        'noCkeck' : true
    },


    {
        'name' : 'mas_exceptions6',
        'label' : 'אני הורה במשפחה חד הורית',
        'defaultValue': false,
        'noCkeck' : true
    },


    {   //40
        'name' : 'mas_exceptions7',
        'label' : 'בגין ילדיי שבחזקתי',
        'defaultValue': false,
        'noCkeck' : true
    },

    {
        'name' : 'mas_exceptions7_1',
        'label' : 'מספר ילדים שנולדו בשנת המס',
        'defaultValue': '',
        'noCkeck' : true
    },

    {
        'name' : 'mas_exceptions7_2',
        'label' : 'מספר ילדים שימלאו להם 6 שנים עד 17 שנים ',
        'defaultValue': '',
        'noCkeck' : true
    },

    {
        'name' : 'mas_exceptions7_3',
        'label' : 'מספר ילדים שימלאו להם שנה אחת עד 5 שנים ',
        'defaultValue': '',
        'noCkeck' : true
    },

    {
        'name' : 'mas_exceptions7_4',
        'label' : 'מספר ילדים שימלאו להם 18 שנים בשנת המס',
        'defaultValue': '',
        'noCkeck' : true
    },


    {   //45
        'name' : 'mas_exceptions8',
        'label' : 'בגין ילדיי הפעוטים',
        'defaultValue': false,
        'noCkeck' : true
    },

    {
        'name' : 'mas_exceptions8_1',
        'label' : 'מספר ילדים שנולדו בשנת המס',
        'defaultValue': '',
        'noCkeck' : true
    },

    {
        'name' : 'mas_exceptions8_2',
        'label' : 'מספר ילדים שימלאו להם שנה אחת עד 5 שנים ',
        'defaultValue': '',
        'noCkeck' : true
    },


    {   //48
        'name' : 'mas_exceptions9',
        'label' : 'אני הורה יחיד לילדיי שבחזקתי',
        'defaultValue': false,
        'noCkeck' : true
    },

    {   //49
        'name' : 'mas_exceptions10',
        'label' : 'בגין ילדיי שאינם בחזקתי',
        'defaultValue': false,
        'noCkeck' : true
    },

    {   //50
        'name' : 'mas_exceptions11',
        'label' : 'אני הורה ל-',
        'defaultValue': '',
        'noCkeck' : true
    },

    {   //51
        'name' : 'mas_exceptions12',
        'label' : 'בגין מזונות לבן/בת זוגי לשעבר',
        'defaultValue': '',
        'noCkeck' : true
    },

    {   //52
        'name' : 'mas_exceptions13',
        'label' : 'מלאו לי או לבן/בת זוגי 16 שנים וטרם מלאו לי או לבן/בת זוגי 18 שנים בשנת המס.',
        'defaultValue': '',
        'noCkeck' : true
    },

    {   //53
        'name' : 'mas_exceptions14',
        'label' : 'אני חייל/ת משוחרר/ת / שרתתי בשירות לאומי.',
        'defaultValue': '',
        'noCkeck' : true
    },

    {   //54
        'name' : 'mas_exceptions14_date1',
        'label' : 'תאריך תחילת השירות',
        'defaultValue': current_datetime,
        'noCkeck' : true
    },

    {   //55
        'name' : 'mas_exceptions14_date2',
        'label' : 'תאריך סיום השירות',
        'defaultValue': current_datetime,
        'noCkeck' : true
    },

    {   //56
        'name' : 'mas_exceptions15',
        'label' : 'בגין סיום לימודים לתואר אקדמי, סיום התמחות או סיום לימודי מקצוע. מצורפת הצהרה בטופס 119.',
        'defaultValue': '',
        'noCkeck' : true
    },

    {   //57
        'name' : 'mas_exceptions4_date2',
        'label' : 'עד תאריך',
        'defaultValue': current_datetime,
        'noCkeck' : true
    },

    {   //58
        'name' : 'taxes_1',
        'label' : 'לא היתה לי הכנסה מתחילת שנת המס הנוכחית עד לתחילת עבודתי אצל מעביד זה',
        'defaultValue': false,
        'noCkeck' : true
    },

    {   //59
        'name' : 'taxes_2',
        'label' : 'יש לי הכנסות נוספות ממשכורת. כמפורט להלן:',
        'defaultValue': false,
        'noCkeck' : true
    },

    {   //60
        'name' : 'taxes_3',
        'label' : 'פקיד השומה אישר תיאום לפי אישור מצורף',
        'defaultValue': false,
        'noCkeck' : true
    },
]


/* 

signer_address: '',
        signer_passport: '',
        date_birth: formApiData?.signer.date_birth ? convertDBDate(formApiData.signer.date_birth) : current_datetime,
        date_aliya: current_datetime,
        sex: 'מין', */