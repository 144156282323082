//const today = new Date();
//const current_datetime = new Date(today.toString().replace(/-/g, '/'));

export const filesCategories = [
    {
        id: "1",
        name: "צילום שיק ביטחון"
    },
   
];

export const inputsBuilder = [

    {
        'name' : 'signer_tz',
        'label' : 'תעודת זהות',
        'type' : 'number',
        'defaultValue': '',
        
        
    },
    {
        'name' : 'signer_fullName',
        'label' : 'שם מלא',
        'defaultValue': ''
    },
    {
        'name' : 'loanAmount',
        'label' : 'סכום הלוואה מבוקשת',
        'defaultValue': '',
        //'noCkeck' : true
    },
    {
        'name' : 'pays',
        'label' : 'מספר תשלומים',
        'options' : [
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10',
                    //'',
                    //'',
                ],
        'defaultValue': 'מספר תשלומים',
        //'noCkeck' : true
    },
    {
        'name' : 'loanMethod',
        'label' : 'אופן קבלת ההלוואה',
        'options' : [
                    'השכר החודשי הקרוב',
                    'בשיק עם המפרעות',
                    'לחשבון הבנק עם המפרעות'
                    //'',
                    //'',
                ],
        'defaultValue': 'אופן קבלת ההלוואה',
        //'noCkeck' : true
    },
    {
        'name' : 'checkType',
        'label' : 'האם לציין בשיק "למוטב בלבד"?',
        'options' : [
                    'כן',
                    'לא'
                    //'',
                    //'',
                ],
        'defaultValue': 'האם לציין בשיק "למוטב בלבד"?',
        'noCkeck' : true
    },
]


/* 

signer_address: '',
        signer_passport: '',
        date_birth: formApiData?.signer.date_birth ? convertDBDate(formApiData.signer.date_birth) : current_datetime,
        date_aliya: current_datetime,
        sex: 'מין', */